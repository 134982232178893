// extracted by mini-css-extract-plugin
export var bannerSuper = "privacy-policy-module--bannerSuper--DiFLa";
export var blankLine = "privacy-policy-module--blankLine--2ZUQU";
export var button = "privacy-policy-module--button--xlHJx";
export var cASLOGOIcon = "privacy-policy-module--cASLOGOIcon--htEmc";
export var canadianSupply = "privacy-policy-module--canadianSupply--3FE3M";
export var caption = "privacy-policy-module--caption--msCzb";
export var ccs = "privacy-policy-module--ccs--ZP7rs";
export var column = "privacy-policy-module--column--tajR2";
export var contactUs = "privacy-policy-module--contactUs--ZtIfm";
export var display = "privacy-policy-module--display--2AeDR";
export var display1 = "privacy-policy-module--display1--vD8yG";
export var docTemplate = "privacy-policy-module--docTemplate--ASDET";
export var email = "privacy-policy-module--email--hStFA";
export var firstAndLastName = "privacy-policy-module--firstAndLastName--t-jV4";
export var firstAndLastNameEmailAddr = "privacy-policy-module--firstAndLastNameEmailAddr--YfeCV";
export var footer = "privacy-policy-module--footer--M+871";
export var footerContent = "privacy-policy-module--footerContent--9kuNQ";
export var footerL = "privacy-policy-module--footerL--kI9rJ";
export var footerLinks = "privacy-policy-module--footerLinks--Bv700";
export var footerR = "privacy-policy-module--footerR--VsHDi";
export var fullNavBar = "privacy-policy-module--fullNavBar--DJrOh";
export var iconButton = "privacy-policy-module--iconButton--uYzBM";
export var iconFilled = "privacy-policy-module--iconFilled--7PUpw";
export var infowebsitecom = "privacy-policy-module--infowebsitecom--JUWLN";
export var logo = "privacy-policy-module--logo--ljlq1";
export var logo1 = "privacy-policy-module--logo1--Wz7bB";
export var managingYourAccount = "privacy-policy-module--managingYourAccount--lop+i";
export var navbarContent = "privacy-policy-module--navbarContent--D1sbK";
export var navbarContentMobile = "privacy-policy-module--navbarContentMobile--aU3HY";
export var navigation = "privacy-policy-module--navigation--Bfb1V";
export var navigation1 = "privacy-policy-module--navigation1--K6am9";
export var p = "privacy-policy-module--p--xVg34";
export var p1 = "privacy-policy-module--p1--kmci7";
export var personalData = "privacy-policy-module--personalData--2tVqq";
export var privacyPolicy = "privacy-policy-module--privacyPolicy--7d6oQ";
export var privacyPolicy1 = "privacy-policy-module--privacyPolicy1--CG6vg";
export var shippingPolicy = "privacy-policy-module--shippingPolicy--ImhZv";
export var subtitle = "privacy-policy-module--subtitle--2KeGg";
export var subtitleLarge = "privacy-policy-module--subtitleLarge--CHws9";
export var textColumn = "privacy-policy-module--textColumn--xYC4V";
export var textbody = "privacy-policy-module--textbody--gW9q-";
export var textbody1 = "privacy-policy-module--textbody1--TSIx9";
export var textbody2 = "privacy-policy-module--textbody2--7r-bR";
export var theSameInformationCollectio = "privacy-policy-module--theSameInformationCollectio--+Uzlu";
export var visaIcon = "privacy-policy-module--visaIcon--vpZLG";