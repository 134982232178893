import * as React from "react";
import { FunctionComponent, useState, useCallback } from "react";
import { navigate } from "gatsby";
import { Link } from "@chakra-ui/react";
import * as styles from "./privacy-policy.module.css";
import Layout from "../components/Base/Layout";
import { AnchorLink } from "gatsby-plugin-anchor-links";

const PrivacyPolicy: FunctionComponent = () => {

  const onButton1Click = useCallback(() => {
    navigate("/about-us");
  }, []);

  const onButton2Click = useCallback(() => {
    const anchor = document.querySelector(
      "[data-scroll-to='contactSectionContainer']"
    );
    if (anchor) {
      anchor.scrollIntoView({ block: "start", behavior: "smooth" });
    }
  }, []);

  return (
    <>
    <Layout>
      <div className={styles.privacyPolicy}>
        <div className={styles.docTemplate}>
          <div className={styles.textColumn}>
            <div className={styles.subtitleLarge}>
              <b className={styles.display}>PRIVACY POLICY</b>
            </div>
            <div className={styles.textbody}>
              <div className={styles.p}>
                <div className={styles.caption}>
                  This Privacy Policy describes our policies and procedures for
                  collecting, using, and disclosing your information when you
                  use our services. We have established guidelines and
                  principles for handling customers’ information while
                  preserving privacy and security.
                </div>
              </div>
              <div className={styles.p1}>
                <div className={styles.caption}>
                  By using our service, you agree to the collection and use of
                  personal information as per this Privacy Policy. To process
                  product requests and purchases, we will collect detailed
                  customer information and obtain consent to disclose the
                  available data to our service providers/partners and
                  healthcare professionals for better transactions.
                </div>
              </div>
            </div>
            <div className={styles.subtitle}>
              <div className={styles.display1}>
                Collecting and Using Your Personal Information
              </div>
            </div>
            <div className={styles.textbody1}>
              <div className={styles.p}>
                <b className={styles.caption}>Types of Data Collected</b>
              </div>
            </div>
            <div className={styles.textbody2}>
              <div className={styles.p}>
                <div className={styles.caption}>
                  <p className={styles.personalData}>
                    <b>Personal Data</b>
                  </p>
                  <p className={styles.blankLine}>
                    <span>&nbsp;</span>
                  </p>
                  <p className={styles.personalData}>
                    <span>
                      As you use our service, you will be asked to provide us
                      with your personal data to use for contacting and
                      identifying purposes. Your personal data or information
                      may include, but is not limited to:
                    </span>
                  </p>
                  <p className={styles.personalData}>
                    <span>&nbsp;</span>
                  </p>
                  <ul className={styles.firstAndLastNameEmailAddr}>
                    <li className={styles.firstAndLastName}>
                      <span>First and last name</span>
                    </li>
                    <li className={styles.firstAndLastName}>
                      <span>Email address</span>
                    </li>
                    <li className={styles.firstAndLastName}>
                      <span>Phone number</span>
                    </li>
                    <li>
                      <span>
                        Address (Street address, state, city, province,
                        ZIP/Postal code)
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className={styles.textbody2}>
              <div className={styles.p}>
                <div className={styles.caption}>
                  <ol style={{listStyleType: "none", padding: "0"}} className={styles.firstAndLastNameEmailAddr}>
                    <li className={styles.firstAndLastName}>
                      <b>Usage Data</b>
                    </li>
                  </ol>
                  <p className={styles.blankLine}>
                    <span>&nbsp;</span>
                  </p>
                  <p className={styles.personalData}>
                    <span>
                      Our system will automatically collect your usage data when
                      using our service. The information under your usage date
                      includes the following:
                    </span>
                  </p>
                  <p className={styles.personalData}>
                    <span>&nbsp;</span>
                  </p>
                  <ul className={styles.firstAndLastNameEmailAddr}>
                    <li className={styles.firstAndLastName}>
                      <span>
                        Your device’s internet protocol address (IP address)
                      </span>
                    </li>
                    <li className={styles.firstAndLastName}>
                      <span>Type of browser</span>
                    </li>
                    <li className={styles.firstAndLastName}>
                      <span>Version of browser</span>
                    </li>
                    <li className={styles.firstAndLastName}>
                      <span>Page you visit</span>
                    </li>
                    <li className={styles.firstAndLastName}>
                      <span>Time and date of visit</span>
                    </li>
                    <li className={styles.firstAndLastName}>
                      <span>Unique device identifier</span>
                    </li>
                  </ul>
                  <p className={styles.personalData}>
                    <span>&nbsp;</span>
                  </p>
                  <p className={styles.theSameInformationCollectio}>
                    <span>
                      The same information collection will also happen if you
                      use a mobile device. We may collect details, including the
                      type of mobile device you use, unique device ID, operating
                      system, and IP address.
                    </span>
                  </p>
                </div>
              </div>
            </div>
            <div className={styles.textbody2}>
              <div className={styles.p}>
                <div className={styles.caption}>
                  <p className={styles.personalData}>
                    <b>Use of Your Personal Data</b>
                  </p>
                  <p className={styles.blankLine}>
                    <span>&nbsp;</span>
                  </p>
                  <p className={styles.personalData}>
                    <span>
                      The Canadian Supply reserves the right to use your data
                      for the following purposes:
                    </span>
                  </p>
                  <p className={styles.personalData}>
                    <span>&nbsp;</span>
                  </p>
                  <ul className={styles.firstAndLastNameEmailAddr}>
                    <li className={styles.firstAndLastName}>
                      <b
                        className={styles.managingYourAccount}
                      >{`Managing your account `}</b>
                      <span>
                        – To manage your registration as a user or customer of
                        our service. Your data will provide you with access to
                        our service’s functionalities.
                      </span>
                    </li>
                    <li className={styles.firstAndLastName}>
                      <b className={styles.managingYourAccount}>
                        Contact and updating
                      </b>
                      <span className={styles.managingYourAccount}>
                        {" "}
                        – We will use your information to contact you through
                        email, telephone calls, SMS, or other forms of
                        electronic communication. We also provide updates
                        related to our service’s functionalities and product
                        implementations.
                      </span>
                    </li>
                    <li className={styles.firstAndLastName}>
                      <b className={styles.managingYourAccount}>
                        Performing your contract
                      </b>
                      <span className={styles.managingYourAccount}>
                        {" "}
                        – This includes the development, compliance, and
                        undertaking of your purchases with our products.
                      </span>
                    </li>
                    <li className={styles.firstAndLastName}>
                      <b className={styles.managingYourAccount}>
                        Notifying with offers
                      </b>
                      <span className={styles.managingYourAccount}>
                        {" "}
                        – As part of our service, we occasionally inform you of
                        special offers, goods, services, and events in which you
                        can participate. However, you can opt not to receive
                        such information or details.
                      </span>
                    </li>
                    <li className={styles.firstAndLastName}>
                      <b className={styles.managingYourAccount}>
                        Other purposes
                      </b>
                      <span className={styles.managingYourAccount}>
                        {" "}
                        – We may use your personal information for other
                        purposes related to our company’s promotional campaigns,
                        service improvement, product and services, and marketing
                        for a better purchase experience.
                      </span>
                    </li>
                    <li>
                      <b
                        className={styles.managingYourAccount}
                      >{`With Your consent: `}</b>
                      <span className={styles.managingYourAccount}>
                        We may disclose your personal information for any other
                        purpose.
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className={styles.textbody1}>
              <div className={styles.p}>
                <div className={styles.caption}>
                  <p className={styles.personalData}>
                    <b>Disclosure of Your Personal Data</b>
                  </p>
                  <p className={styles.blankLine}>
                    <span>&nbsp;</span>
                  </p>
                  <p className={styles.theSameInformationCollectio}>
                    <span>
                      The Canadian Supply acts in accordance with the disclosure
                      of your personal data involving the following principles,
                      transactions, and essential requirements:
                    </span>
                  </p>
                </div>
              </div>
            </div>
            <div className={styles.textbody2}>
              <div className={styles.p}>
                <div className={styles.caption}>
                  <ul className={styles.firstAndLastNameEmailAddr}>
                    <li className={styles.firstAndLastName}>
                      <b
                        className={styles.managingYourAccount}
                      >{`Law enforcement – `}</b>
                      <span>
                        The Canadian Supply shall not use or disclose your
                        personal information for purposes other than for which
                        it was collected, except for legal obligations or as
                        required by law. Our company may be required to disclose
                        your details if it’s subject to public authorities or
                        government agencies.
                      </span>
                    </li>
                    <li className={styles.firstAndLastName}>
                      <b
                        className={styles.managingYourAccount}
                      >{`Other legal requirements `}</b>
                      <span className={styles.managingYourAccount}>
                        - The Canadian Supply may disclose your personal data in
                        the good faith belief that such action is necessary to
                        comply with a legal obligation, defend the rights of the
                        company, investigate possible wrongdoings within the
                        service, protect the personal safety of the users, and
                        safeguard against legal liability.
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className={styles.textbody1}>
              <div className={styles.p}>
                <div className={styles.caption}>
                  <p className={styles.personalData}>
                    <b>Security of Your Personal Data</b>
                  </p>
                  <p className={styles.blankLine}>
                    <b>&nbsp;</b>
                  </p>
                  <p className={styles.theSameInformationCollectio}>
                    <span>
                      The security of your personal data is important to us. The
                      Canadian Supply shall employ security safeguards to
                      protect your personal information against unauthorized
                      access, disclosure, modification, illegal use, loss or
                      theft, and copying. When you enter sensitive information
                      (such as a credit card number) on our order forms, we
                      encrypt the transmission of that information using secure
                      socket layer technology (SSL). While there is no method to
                      make your personal information 100% secure, we strive to
                      use commercially acceptable means to protect your personal
                      data.
                    </span>
                  </p>
                </div>
              </div>
            </div>
            <div className={styles.textbody1}>
              <div className={styles.p}>
                <div className={styles.caption}>
                  <p className={styles.personalData}>
                    <b>Delete Your Personal Data</b>
                  </p>
                  <p className={styles.personalData}>
                    <b>&nbsp;</b>
                  </p>
                  <p className={styles.personalData}>
                    <span>
                      The Canadian Supply allows you to delete certain
                      information from your registered account. You can also
                      request to remove any data we have collected from you. Our
                      service helps you amend or update your information at any
                      time. Just visit the account settings and edit your
                      personal information.
                    </span>
                  </p>
                  <p className={styles.personalData}>
                    <span>&nbsp;</span>
                  </p>
                  <p className={styles.theSameInformationCollectio}>
                    <span>
                      You may also contact us and request us to correct or
                      delete any information you want to change or omit.
                      However, note that we may need to retain certain
                      information when we have a legal obligation.
                    </span>
                  </p>
                </div>
              </div>
            </div>
            <div className={styles.textbody1}>
              <div className={styles.p}>
                <div className={styles.caption}>
                  <p className={styles.personalData}>
                    <b>Use of Cookies</b>
                  </p>
                  <p className={styles.personalData}>
                    <span>&nbsp;</span>
                  </p>
                  <p className={styles.theSameInformationCollectio}>
                    <span>
                      Through the use of cookies, we track your activities on our
                      service. We use session cookies to collect certain
                      information to improve our site’s accessibility and
                      protection. You can opt to disable the use of cookies by
                      simply adjusting the web browser settings accordingly. You
                      can easily choose from refusing or allowing our service to
                      use cookies.
                    </span>
                  </p>
                </div>
              </div>
            </div>
            <div className={styles.subtitle}>
              <div className={styles.display1}>
                Changes to this Privacy Policy
              </div>
            </div>
            <div className={styles.textbody1}>
              <div className={styles.p}>
                <b className={styles.caption}>
                  We may update and make some changes to this privacy statement.
                  We will notify you of any changes by posting a newly detailed
                  Privacy Policy on this page. We will inform you by sending you
                  an email (e-mail address registered on your account) before
                  the change becomes effective. You should review this page
                  periodically for any changes.
                </b>
              </div>
            </div>
            <div className={styles.subtitle}>
              <div className={styles.display1}>Contact us</div>
            </div>
            <div className={styles.textbody1}>
              <div className={styles.p}>
                <b className={styles.caption}>
                  The Canadian Supply recognizes the utmost importance of
                  customer satisfaction. Should you have any inquiries,
                  concerns, or problems regarding this Privacy Policy, you can
                  contact us directly through our Privacy Officer on our <AnchorLink to="/#contactSectionContainer"><a className={styles.contactUs}>contact
                  us</a></AnchorLink> page, or emailing us at <Link href="mailto:info@thecanadiansupply.com" className={styles.contactUs}>info@thecanadiansupply.com</Link>
                </b>
              </div>
            </div>
          </div>
        </div>
      </div>
      </Layout>                      
    </>
  );
};

export default PrivacyPolicy;
